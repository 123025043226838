<template>
    <v-app>
        <div class="frame-popup">
            <header>
                <h1>고객정보(MEMO) 이력</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main>
                <div class="jh-form-wrap">
                    <div class="jh-block is-bg-light-yellow jh-cols is-mb-10">
                        <label class="jh-label">고객번호 :</label>
                        <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" style="max-width:90px" readonly v-model="CLNT_NO">
                        <label class="jh-label">고객명 :</label>
                        <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" readonly v-model="CLNT_NM">
                    </div>
                    <div class="jh-ui-header">
                        <h2>고객정보(MEMO) 리스트</h2>
                        <div class="is-right"></div>
                    </div>
                    <data-tables
                    ref="dataTable"
                    dataTableClass=""
                    :data-table-options="dataTableOptions"
                    @click:row="onClickRow"
                    :paginationOptions="paginationOptions"
                    >
                        <template v-slot:item.text="{ item }"><div class="is-ellips" :title="item.text">{{ item.text }}</div></template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="is-p-10">{{ item.CUSTOMER_MEMO }}</td>
                        </template>
                    </data-tables>
                </div>
            </main>
            <!-- <footer><v-btn class="jh-btn is-md is-main" @click="close">선택</v-btn></footer> -->
        </div>
    </v-app>
</template>

<script>
import api from '../../store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex"
import dataTables from "@/components/DataTables";

export default {
    name: "MENU_P100901", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
    },
    computed: {
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },
        ...mapGetters({
            geCustInfo: 'callStore/GE_CUST_INFO'
        }),
    },
    components: {
        dataTables
    },
    data() {
        return {
            cusMemoheaders: [
                { text: '순번', value: 'ROWNUM', align: 'center', width: '50px' , sortable: false },
                { text: '등록일', value: 'MEMO_REGR_DATE', align: 'center', width: '110px'},
                // { text: '고객번호', value: 'CUST_NO', align: 'center', width: '100px'},
                // { text: '고객명', value: 'CUSTOMER_NM', align: 'center', width: '70px' },
                { text: '내용', value: 'CUSTOMER_MEMO' },
                { text: '처리자(사번)', value: 'REG_MAN', align: 'center', width: '110px' },
            ],
            gridCustMemoItems: [],

            CLNT_NO: "",
            CLNT_NM: "",
            
            isLoading: false,
            menuStartDate: "",
            menuEndDate: "",      
            
            // dates: [
            //     (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            //     (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            // ],

            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '350px',
                itemKey: 'ROWNUM',
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,
                singleExpand: true,
                load: this.selectCusMemoHst,
            },
            paginationOptions: {
                totalVisible: 10
            },
        };
    },

    created() {
        this.dataTableOptions.headers = this.cusMemoheaders;
        //this.dataTableOptions.items = this.gridCustMemoItems;
    },

    methods: {
        async initData(){
            this.isLoading = false;

            this.CLNT_NO = this.geCustInfo.CLNT_NO;
            this.CLNT_NM = this.geCustInfo.CNM;

            // let preDate = await this.mixin_getSvrPrevDate("%Y%m%d");

            // this.startDate(this.mixin_dateFormat(preDate.TO_DAY_7));
            // this.endDate(this.mixin_dateFormat(preDate.TO_DAY));
        },
        getList() {
            if(!this.geCustInfo.CLNT_NO){
                this.common_alert("고객번호는 필수입니다.", "error");
                return false
            }

            // if (!this.dates[0] && !this.dates[1]) {
            //     this.common_alert("조회기간은 필수입니다.", "error");
            //     return false
            // }
            this.isLoading = true;

            if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
            else this.dataTableInstance.loadData();
            // else this.selectCusMemoHst(loadData);
        },

        selectCusMemoHst: async function (loadOptions) {
            if(!this.geCustInfo.CLNT_NO){
                this.common_alert("고객번호는 필수입니다.", "error");
                return false
            }

            // if (!this.dates[0] && !this.dates[1]) {
            //     this.common_alert("조회기간은 필수입니다.", "error");
            //     return false
            // }
            console.log("$$$$::"+ JSON.stringify(loadOptions));

            // header 세팅
            let requestData = {
                headers: {
                ASYNC: false,
                METHOD: "list",
                SERVICE: "phone.main.cus-memo",
                TYPE: "BIZ_SERVICE",
                ROW_CNT: loadOptions.itemsPerPage,
                PAGES_CNT: loadOptions.page,
                URL: "/api/phone/main/cus-memo/list"
                },
                sendData: {},
            };

            requestData.sendData["SORT_DRCT"] = "desc";
            requestData.sendData["ROW_ORDR"] = "";
            requestData.sendData["SORT_ORDR"] = "IFNULL(MEMO_REGR_DATE, '999999999')";
            if (loadOptions.sortBy && loadOptions.sortBy.length > 0) {
                if(loadOptions.sortBy[0] != "ROWNUM"){
                    requestData.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
                }
                if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0) {
                    requestData.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
                    if(loadOptions.sortBy[0] == "ROWNUM") requestData.sendData["ROW_ORDR"] = loadOptions.sortDesc[0] ? "desc" : "asc";
                }
            }

            requestData.sendData["CUST_NO"] = this.geCustInfo.CLNT_NO;
            // requestData.sendData["MEMO_STT_DATE"] = this.dates[0].replaceAll("-", "");
            // requestData.sendData["MEMO_END_DATE"] = this.dates[1].replaceAll("-", "");   
            this.isLoading = true;
            return this.common_postCall(requestData).then(response => {
                this.isLoading = false;

                this.gridCustMemoItems = [];
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert(`고객메모 등록 중 오류가 발생하였습니다.`, "error");
                    return false;
                }
                //console.log("$$$$::"+ JSON.stringify(response));

                let header = response.HEADER;
                let data = response.DATA;
                if (!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                    if(!this.mixin_isEmpty(response.DATA) && response.DATA.length > 0) {
                        for(let d of response.DATA) {
                            for(let k of Object.keys(d)) {
                                if(k === 'CUSTOMER_MEMO') d[k] = this.restoreXSS(d[k]).replaceAll("<BR>", "\r");
                                else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
                            }
                            this.gridCustMemoItems.push(d)
                        }
                    }
                }
                this.isLoading = false;
                return {
                    data: this.gridCustMemoItems,
                    totalCount: header.COUNT,
                };
                // } else {
                //     this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
                // }
                // this.common_alert("정상 처리되었습니다.", "done");
            });
        },
        
        close(){
            self.close();
        },
        startDate(e) {
            this.dates[0] = e;
        },
        endDate(e) {
            this.dates[1] = e;
        },
        onClickRow: function (item, row) { 
            console.log(item);
            if(this.selectedId===item.ROWNUM){
                row.select(false);
                row.expand(false);
                this.selectedId=item.name;
            }else{
                row.select(true);
                this.selectedId=item.ROWNUM;
                row.expand(true);
            }
        },
    },
    mounted(){
        // 토큰 동기화를 위해 메인창에 window 객체에 팝업 windows 객체 저장
        // window 팝업에서 palette api를 호출할 경우 필수
        if (opener) {
            if (!opener.popups) opener.popups = [];
                opener.popups.push(self);
        }  
        this.initData();
    },
    watch: {
    }
};
</script>

<style></style>